import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heding from "../atoms/newsletter-heading"
import Wrapper from "../atoms/page-wrapper"

const FacilitiesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Image = styled(Img)`
  display: block;
  width: 125px;
  height: 125px;
  object-fit: none;
  object-position: left;
  border-radius: 500px;
`

const FacilitiesItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: 6rem;
  position: relative;

  ::after {
    content: "";
    display: block;
    width: 70px;
    height: 2px;
    background-color: #bebabe;
    position: absolute;
    bottom: 0;
    left: 153px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    ::after {
      left: 50%;
      transform: translateX(-50%);
      bottom: -10px;
    }
  }
`

const FacilitiesDescWrapper = styled.div`
  margin-left: 3rem;

  h4 {
    margin-top: 0;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.secondary};
  }

  address {
    line-height: 1.5;
    font-size: 1.8rem;
  }

  @media (max-width: 1000px) {
    margin-left: 0rem;
    margin-top: 2rem;
  }
`

const facilities = [
  {
    name: "Siedziba główna",
    address1: "ul. Wojska Polskiego 25",
    address2: "44-120 Pyskowice",
    fluidParamName: "pyskowice1",
  },
  {
    name: "Szkoła Podstawowa Im. Jana Pawła II w Wielowsi",
    address1: "ul. Zamkowa 24",
    address2: "44-187 Wielowieś",
    fluidParamName: "wielowies",
  },
]

const OurFacilities = props => (
  <Wrapper>
    <ParagraphItalic>nasze-placowki</ParagraphItalic>
    <Heding>W tych miejscach uczymy</Heding>
    <FacilitiesList>
      {facilities.map(({ name, address1, address2, fluidParamName }) => (
        <FacilitiesItem key={`faci-${name}`}>
          <Image
            fluid={props[fluidParamName]}
            alt={`Placówka AMUZO w ${address2} na ${address1}`}
          />
          <FacilitiesDescWrapper>
            <h4>{name}</h4>
            <address>
              {address1} <br /> {address2}
            </address>
          </FacilitiesDescWrapper>
        </FacilitiesItem>
      ))}
    </FacilitiesList>
  </Wrapper>
)

OurFacilities.propTypes = {
  pyskowice1: PropTypes.object.isRequired,
  wielowies: PropTypes.object.isRequired,
}

export default OurFacilities
